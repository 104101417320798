<template>
  <div class="content">
    <div class="flex col-center">
      <img src="@/assets/images/logo.png" alt="" />
      <div class="text-box flex youShe">
        <div class="text">D</div>
        <div class="text">D&nbsp;</div>
        <div class="text">T</div>
        <div class="text">o</div>
        <div class="text">k</div>
        <div class="text">y</div>
        <div class="text">o</div>
      </div>
      <div class="car">
        <div class="iconfont icon-shouyedongxiaoxiaoche"></div>
        <!--        <svg-->
        <!--          t="1715994769319"-->
        <!--          class="icon"-->
        <!--          viewBox="0 0 1925 1024"-->
        <!--          version="1.1"-->
        <!--          fill="#bbb290"-->
        <!--          xmlns="http://www.w3.org/2000/svg"-->
        <!--          p-id="12566"-->
        <!--          width="200"-->
        <!--          height="200"-->
        <!--        >-->
        <!--          <path-->
        <!--            d="M1056.43867 861.932704h-312.129607c-6.002492-126.052341-108.044864-222.09222-234.097205-222.09222-126.052341 0-228.094713 102.042372-234.097206 222.09222H120.049849c-30.012462 0-60.024924-30.012462-90.037387-84.034894-42.017447-84.034894-42.017447-228.094713 0-288.119637 24.00997-42.017447 78.032402-60.024924 156.064804-60.024925C294.12213 219.666013 408.169486 93.613672 534.221827 51.596225c186.077266-66.027417 474.196903-60.024924 576.239275-30.012463 102.042372 30.012462 300.124622 90.037387 432.179455 348.144562 78.032402 48.01994-48.01994 0 186.077266 60.024924 234.097205 60.024924 186.077266 264.109667 186.077266 348.144562 0 54.022432-36.014955 84.034894-102.042372 84.034894h-288.119637c-6.002492-126.052341-108.044864-222.09222-234.097205-222.09222s-234.097205 102.042372-234.097205 222.09222zM522.216842 1024c-84.034894 0-150.062311-66.027417-150.062311-150.062311S438.181948 723.875378 522.216842 723.875378s150.062311 66.027417 150.062311 150.062311S606.251737 1024 522.216842 1024z m762.31654 0c-84.034894 0-150.062311-66.027417-150.062311-150.062311S1200.498488 723.875378 1284.533382 723.875378s150.062311 66.027417 150.062311 150.062311-66.027417 150.062311-150.062311 150.062311zM396.164501 405.743279h372.154531V129.628626c-132.054834 0-228.094713 30.012462-288.119637 96.039879s-90.037387 126.052341-84.034894 180.074774z m900.373866 0c-18.007477-66.027417-66.027417-132.054834-138.057326-192.079759-78.032402-60.024924-192.079758-90.037387-348.144561-78.032401v270.11216h486.201887z"-->
        <!--            p-id="12567"-->
        <!--          ></path>-->
        <!--        </svg>-->
      </div>
    </div>
    <!--    <div class="ltd">-->
    <!--      DD Tokyo-->
    <!--    </div>-->
  </div>
</template>
<script>
export default {
  name: 'CarDrive'
}
</script>

<style scoped lang="scss">
.content {
  position: fixed;
  left: 100px;
  top: 36px;
  color: $primary-color;

  img {
    width: 60px;
    height: 64px;
    margin: 0 14px 10px 0;
  }

  .text-box {
    font-size: 30px;
    .text {
      transform: rotate(10deg);
      animation: textShow 400ms ease 1s 1 normal forwards;
      opacity: 0;
      @keyframes textShow {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(10deg);
        }

        50% {
          transform: rotate(-10deg);
        }

        75% {
          transform: rotate(10deg);
        }

        100% {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
    }
  }
  .car {
    animation: drive 1s 1 ease-in forwards;
    //width: 50px;
    //height: 50px;
    margin-left: 10vw;
    .icon-shouyedongxiaoxiaoche {
      font-size: 80px;
      color: $primary-color;
    }
    @keyframes drive {
      98% {
        opacity: 1;
      }
      100% {
        transform: translateX(-5vw);
        opacity: 0;
        margin-left: 0;
      }
    }
  }
  .ltd {
    font-family: Inter;
    font-size: 20px;
    opacity: 0;
    animation: ltdShow 1s ease 1s 1 normal forwards;
    @keyframes ltdShow {
      100% {
        opacity: 1;
      }
    }
  }
}
</style>
