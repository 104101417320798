<template>
  <div class="video-box">
    <video :src="path" id="cover-video" autoplay muted loop v-if="flag"></video>
    <object v-else width="100%" :height="height" :data="path">
      <param name="movie" :value="path" />
      <param name="allowFullScreen" value="true" />
      <param name="flashvars" value="mute=true" />
      <embed
        :src="path"
        type="application/x-shockwave-flash"
        allowfullscreen="true"
        flashvars="mute=true"
        width="100%"
        :height="height"
      />
    </object>
    <div class="slogan-box">
      <div>
        <div class="slogan-btn">
          <div class="slogan">
            {{ $t('home.slogan') }}
          </div>
          <div class="car-btn flex row-center col-center" @click="order">
            {{ $t('home.useCar') }}
          </div>
        </div>
      </div>
    </div>
    <online-dialog ref="onlineDialog" />
  </div>
</template>
<script>
import OnlineDialog from '@/components/Common/OnlineDialog/OnlineDialog.vue'

export default {
  name: 'CustomVideo',
  components: { OnlineDialog },
  data() {
    return {
      height: '',
      flag: true,
      path: process.env.VUE_APP_FILE_URL + 'index/ddvideo.mp4'
    }
  },
  mounted() {
    const ua = window.navigator.userAgent.toLowerCase()
    const new_ie = ua.indexOf('trident/')
    //兼容ie和360兼容模式
    if (window.navigator.mimeTypes[40] || !window.navigator.mimeTypes.length || new_ie !== -1) {
      // this.flag = false
      this.height = window.innerHeight
      const video = document.getElementById('cover-video')
      video.style.height = '100vh'
    }
  },
  methods: {
    order() {
      this.$refs.onlineDialog.show()
    }
  }
}
</script>

<style scoped lang="scss">
.video-box {
  height: 100vh;
  background: $bg-color;
  video {
    object-fit: cover;
    height: calc(100vh - 0px);
    width: 100vw;
  }
  .slogan-box {
    position: absolute;
    top: calc((100% - 609.932px) / 2);
    width: 100%;
    overflow: hidden;
    //left: calc((100% - 650px) / 2);
    .slogan-btn {
      position: relative;
      width: 630px;
      height: 670px;
      margin: 0 auto;
      .slogan {
        border-radius: 0 445.205px 445.205px 0;
        background: rgba(0, 0, 0, 0.3);
        width: 610px;
        height: 650px;
        font-size: 48px;
        line-height: normal;
        //letter-spacing: 3.84px;
        padding: 130px 0 0 90px;
        box-sizing: border-box;
        color: #fff;
        animation: showLeft 1s ease-out;
        white-space: pre-line;
        @keyframes showLeft {
          0% {
            transform: translate(-600px, 0);
          }
          80% {
            transform: translate(0, 0);
          }
          90% {
            transform: translate(-10px, 0);
          }
          100% {
            transform: translate(0, 0);
          }
        }
        span {
          animation: showText 1s linear 1s forwards;
          opacity: 0;
          @keyframes showText {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
      .car-btn {
        width: 200px;
        height: 200px;
        filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.08))
          drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04))
          drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.05));
        background: $primary-color;
        border-radius: 200px;
        color: #fff;
        font-size: 48px;
        font-weight: 400;
        //letter-spacing: 6.72px;
        position: absolute;
        top: 470px;
        left: 430px;
        animation: showRight 1s ease-out;
        cursor: pointer;
        text-align: center;
        @keyframes showRight {
          0% {
            transform: translate(600px, 0);
          }
          80% {
            transform: translate(0, 0);
          }
          90% {
            transform: translate(10px, 0);
          }
          100% {
            transform: translate(0, 0);
          }
        }
      }
    }
  }
}

.zh,
.en {
  .slogan-btn {
    font-family: YouSheBiaoTiHei !important;
  }
}

.zh {
  .slogan {
    font-size: 64px !important;
    letter-spacing: 3.84px;
  }
  .car-btn {
    letter-spacing: 6.72px;
    padding-left: 6.72px;
  }
}
.zhf {
  .slogan {
    letter-spacing: 2.88px;
    font-weight: 700;
  }
  .car-btn {
    letter-spacing: 6.72px;
    padding-left: 6.72px;
    font-weight: 700 !important;
  }
}

.en {
  .car-btn {
    padding: 52px;
    box-sizing: border-box;
  }
}
.ja {
  .car-btn {
    letter-spacing: 0.151px;
    padding-left: 0.151px;
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}
</style>
