<template>
  <div class="index-container">
    <car-drive v-show="!showLogo" />
    <custom-video />
    <about-us id="aboutUs" />
    <centered-auto-swiper :scroll-y="scrollY" :aboutUsHeight="aboutUsHeight" />
  </div>
</template>

<script>
import CarDrive from '@/components/Common/CarDrive/CarDrive.vue'
import CustomVideo from '@/components/Moudle/Index/CustomVideo.vue'
import AboutUs from '@/components/Moudle/Index/AboutUs.vue'
import CenteredAutoSwiper from '@/components/Moudle/CarService/CenteredAutoSwiper.vue'
export default {
  name: 'index',
  components: { CenteredAutoSwiper, AboutUs, CustomVideo, CarDrive },
  props: {
    showLogo: {
      type: Boolean,
      default: false
    },
    scrollY: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      aboutUsHeight: 0
    }
  },
  mounted() {
    this.aboutUsHeight = document.getElementById('aboutUs').offsetHeight
  }
}
</script>

<style scoped lang="scss"></style>
