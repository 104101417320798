<template>
  <div class="about-box flex">
    <div class="left" :class="lang">
      <div class="title weight">{{ $t('home.aboutUs.title') }}</div>
      <div class="text">
        {{ $t('home.aboutUs.content1') }}
      </div>
      <div class="text">
        {{ $t('home.aboutUs.content2') }}
      </div>
      <div class="text">
        {{ $t('home.aboutUs.content3') }}
      </div>
      <div class="text">
        {{ $t('home.aboutUs.content4') }}
      </div>
      <div class="phone">
        <phone />
      </div>
    </div>
    <div class="right">
      <img :src="imgUrl" />
    </div>
  </div>
</template>
<script>
import Phone from '@/components/Common/Phone/phone.vue'

export default {
  name: 'AboutUs',
  components: { Phone },
  data() {
    return {
      lang: '',
      imgUrl: process.env.VUE_APP_FILE_URL + 'index/about/1.png'
    }
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.lang = newValue
    }
  },
  created() {
    this.lang = this.$i18n.locale
  }
}
</script>

<style scoped lang="scss">
.about-box {
  padding: 0 190px;
  background-color: $bg-color;
  color: $light-color;
  .left {
    width: 680px;
    padding: 195px 0 130px;
    .title {
      font-size: 48px;
      line-height: 70px;
    }
    .text {
      font-size: 20px;
      font-weight: 500;
    }
    .weight {
      font-weight: 500;
    }
  }
  .zh,
  .zhf {
    .title {
      margin-bottom: 40px;
    }
    .text {
      margin-bottom: 36px;
      line-height: 40px;
    }
    .phone {
      margin-top: 75px;
    }
  }
  .en,
  .ja {
    .title {
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 20px;
      line-height: 36px;
    }
    .phone {
      margin-top: 30px;
    }
  }
  .right {
    padding-top: 155px;
    margin-left: 120px;
    img {
      width: 740px;
      height: 780px;
      border-radius: 28px;
    }
  }
}
</style>
